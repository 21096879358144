import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './themes/v1/App';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import { AuthProvider } from "./contexts/AuthContext"
import { CartProivder } from "./contexts/CartContext";
import {CookiesProvider} from "react-cookie";
ReactDOM.render(
  <AuthProvider>
    <CartProivder>
      <CookiesProvider>
        <App />
      </CookiesProvider>
    </CartProivder>
  </AuthProvider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
