import { Deserializable } from "./deserializable.interface";
import Model from "./model";
import Page from "./page";

export default class Post extends Page implements Deserializable<Post> {
    id: number = 0;
    image: string = '';
    short_content: string = '';

    fromJson(input: {}) : Post {
        super.fromJson(input);

        return this;
    }
}