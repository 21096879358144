import { Deserializable } from "./deserializable.interface";
import Model from "./model";
import Product from "./product";

export default class Category extends Model implements Deserializable<Category> {
    name!: string;
    slug!: string;
    childrens!: Category[];
    products!: Product[];
    is_devices: boolean = true;

    fromJson(input: {}): Category {
        super.fromJson(input);
        

        return this;
    }

    listCategories(input: []): Category[] {
        let results: Category[] = [];
        if (input.length > 0) {
            results = input.map((item: Category) => {
                return new Category().fromJson(item);
            });
        }
        return results;
    }
}