import Api from './api';
export default class Auth extends Api {
    protected prefix = 'customer';

    login = (credentials: { username: any, password: any }) => {
        return super.post("login", credentials);
    }

    socialLoginUrl = (provider: string) => {
        return super.get(`login/${provider}`);
    }

    socialLoginCallback = async (provider: string, token: string) => {
        const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}login/${provider}/callback?code=${token}`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        });
        if (response.status === 200) {
            return response.json();
        }
        throw response;
    }

    getCurrentUser = (token: string) => {
        return super.get(`${this.prefix}/`, {}, { 'Authorization': `Bearer ${token}` });
    }

    logout = (token: string) => {
        return super.get(`${this.prefix}/logout`, {}, { 'Authorization': `Bearer ${token}` });
    }

    update = (token: string, attributes: {}) => {
        return super.put(`${this.prefix}/update`, attributes, { 'Authorization': `Bearer ${token}` });
    }

    getOTP = (token:string, phone: string) => {
        return super.get(`${this.prefix}/get-otp`, {phone: phone}, { 'Authorization': `Bearer ${token}` });
    } 

    getSubCustomers = (token: string, param: {}) => {
        return super.get(`${this.prefix}/get-sub-customers`, param, { 'Authorization': `Bearer ${token}` });
    }

    addSubCustomer = (token: string, param: {}) => {
        return super.post(`${this.prefix}/sub-customers`, param, { 'Authorization': `Bearer ${token}` });
    }

    getReports = (token: string, params: {}) => {
        return super.get(`${this.prefix}/reports/available`, params, { 'Authorization': `Bearer ${token}` });
    }
    getReportss = (token: string, customer_id: any) => {
        return super.get(`${this.prefix}/reports/available/` + customer_id,{}, { 'Authorization': `Bearer ${token}` });
    }
    getReport = (token: string, report_id: any) => {
        return super.get(`${this.prefix}/report/` + report_id, {}, { 'Authorization': `Bearer ${token}` });
    }

    getReportDetail = (token: string, report_id: any, area: number, param: {}) => {
        return super.get(`${this.prefix}/report/` + report_id + '/' + area, param, { 'Authorization': `Bearer ${token}` });
    }
}