import React from 'react';
import "./header.scss";
import MainMenu from "./../../menus/main-menu";
import MobileMainMenu from "./../../menus/mobile-main-menu";
import {BrowserView, MobileView} from 'react-device-detect';
import CategoryService from '../../../services/category';
import Category from '../../../models/category';
import {getToken} from "../../../actions/AuthAction";

class HeaderProps {
    logo: string = '';
    hotline: string = 'CSKH : ' + process.env.REACT_APP_HOTLINE;
}

class HeaderState {
    categories: Category[] = []
}

export default class Header extends React.Component<HeaderProps, HeaderState> {
    constructor(props: any) {
        super(props);
        this.state = {
            categories: []
        };
    }

    async fetchCategories() {
        const token = getToken();
        const response = await new CategoryService().getCategories(token);
        const cates = new Category().listCategories(response);
        this.setState({
            categories: cates
        });
    }

    componentDidMount() {
        this.fetchCategories();
    }

    render() {
        return (
            <>
                <div className="header-wapper">
                    {/*<Topbar left={this.props.hotline} right={*/}
                    {/*    <>*/}
                    {/*        <BrowserView>*/}
                    {/*            <ul className="top-menu nav justify-content-end">*/}
                    {/*                <li className="nav-item">*/}
                    {/*                    <Link to="/s/tai-tai-lieu" className="text-white">Download</Link>*/}
                    {/*                </li>*/}
                    {/*                <li className="nav-item">*/}
                    {/*                    <Link to="/kick-hoat-bao-hanh" className="text-white">Tra cứu bảo hành</Link>*/}
                    {/*                </li>*/}
                    {/*                <li className="nav-item">*/}
                    {/*                    <Link to="/lich-su-bao-hanh" className="text-white">Lịch sử bảo hành</Link>*/}
                    {/*                </li>*/}
                    {/*                <li className="nav-item">*/}
                    {/*                    <User />*/}
                    {/*                </li>*/}
                    {/*            </ul>*/}
                    {/*        </BrowserView>*/}
                    {/*        <MobileView>*/}
                    {/*            <ul className="top-menu nav justify-content-end">*/}
                    {/*                <li className="nav-item">*/}
                    {/*                    <User />*/}
                    {/*                </li>*/}
                    {/*            </ul>*/}
                    {/*        </MobileView>*/}
                    {/*    </>*/}
                    {/*} />*/}
                    <div className="header container p-0">
                        <BrowserView>
                            <div className="row">
                                <div className="logo col-12 col-sm-2 text-center pt-3">
                                    <a href="/"><img src={this.props.logo} alt="Logo"/></a>
                                </div>
                                <div className="col-12 col-sm-10">
                                    <MainMenu categories={this.state.categories}/>
                                </div>
                            </div>
                        </BrowserView>
                        <MobileView>
                            <MobileMainMenu logo={this.props.logo} categories={this.state.categories}/>
                        </MobileView>
                    </div>
                </div>
                <div className="div_contents"></div>
            </>
        );
    }
}