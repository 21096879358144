import React from 'react';

class Props {
    class: string = "";
    isLoading: boolean = false;
    disabled: boolean = false;
}

export default class Button extends React.Component <Props, {}>{
    static defaultProps = {
        class: '',
        isLoading: false,
        disabled: false,
    };

    classes() {
        return [
            "btn",
            this.props.class
        ].join(" ");
    }

    loading = <div className="spinner-border spinner-border-sm" role="status">
        <span className="sr-only">Loading...</span>
    </div>;

    render() 
    {
        return (
            <button className={this.classes()} disabled={this.props.isLoading || this.props.disabled}>{this.props.isLoading ? this.loading : this.props.children}</button>
        );
    }
}
Button.defaultProps = {
    class: '',
    isLoading: false,
    disabled: false,
}