import React from 'react';
import {Link} from "react-router-dom";
import fb from "../../assets/icons/fb.svg";

interface Props {
    first: any;
    second: any;
    third: any
}

export default class Footer extends React.Component <Props, {}> {
    render() {
        return (
            <div className="footer-three-columns container mb-3">
                <div className="menu_footer">
                    <ul>
                        <li ><Link className="text-light" to="/s/chi-nhanh">SHOWROOMS</Link></li>
                        <li><Link className="text-light" to="/faqs">Hỗ trợ</Link></li>
                        <li><Link className="text-light" to="/lien-he">Liên hệ</Link></li>
                        <li><Link className="text-light" to="/s/ve-chung-toi">Về chúng tôi</Link></li>
                        <li><Link className="text-light" to="/s/quyen-rieng-tu">CHÍNH SÁCH</Link></li>
                        <li><Link className="text-light" to="/s/thoa-thuan">THỎA THUẬN</Link></li>
                        <li><Link className="text-light" to="/tin-tuc">Tin tức</Link></li>
                    </ul>
                </div>
                <div className="row">
                    <div className="col-12 col-sm-4 mb-2 about-left">
                        <div className="contact-info">
                            <p className="mb-4"><strong>CÔNG TY TNHH GIẢI PHÁP CÔNG NGHỆ HUY GIÁP (HGTECHS
                                CO.,LTD)</strong></p>
                            <p className="small"><i className="bi bi-building mr-2"></i>Địa chỉ: Liền kề 18, 114 Đường
                                Thanh Bình, Phường Mộ Lao, Quận Hà Đông, TP. Hà Nội.</p>
                            <p><a href="tel:+84982466668" className="text-light" target="_blank" title="phone">
                                <i className="bi bi-telephone mr-2"></i>+84 982466668
                            </a></p>
                            <p><a href="tel:+84943013331" className="text-light" target="_blank" title="phone">
                                <i className="bi bi-telephone mr-2"></i>+84 943013331
                            </a>
                            </p>
                            <p><Link className="text-light" to="mailto:info@hgtechs.vn"><i
                                className="bi bi-envelope-fill mr-2"></i>info@hgtechs.vn</Link></p>
                        </div>
                    </div>
                    <div className="col-12 col-sm-3 mb-3">
                        <div className="contact-info">
                            <p className="mb-4 heding"><strong>HƯỚNG DẪN</strong></p>
                            <div className="list_menu">
                                <Link className="text-light" to="/s/quyen-rieng-tu">
                                    <svg stroke="currentColor" fill="currentColor" stroke-width="0"
                                         viewBox="0 0 320 512" height="1em" width="1em"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z"></path>
                                    </svg>
                                    Quyền riêng tư</Link><br/>
                                <Link className="text-light" to="/s/thoa-thuan">
                                    <svg stroke="currentColor" fill="currentColor" stroke-width="0"
                                         viewBox="0 0 320 512" height="1em" width="1em"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z"></path>
                                    </svg>
                                    Thoả thuận</Link><br/>
                                <Link className="text-light" to="/kick-hoat-bao-hanh">
                                    <svg stroke="currentColor" fill="currentColor" stroke-width="0"
                                         viewBox="0 0 320 512" height="1em" width="1em"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z"></path>
                                    </svg>
                                    Tra cứu thông tin bảo hành</Link><br/>
                                <Link className="text-light" to="/kick-hoat-bao-hanh">
                                    <svg stroke="currentColor" fill="currentColor" stroke-width="0"
                                         viewBox="0 0 320 512" height="1em" width="1em"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z"></path>
                                    </svg>
                                    Kích hoạt bảo hành</Link><br/>
                                <Link className="text-light" to="/s/huong-dan-thanh-toan-vn-pay">
                                    <svg stroke="currentColor" fill="currentColor" stroke-width="0"
                                         viewBox="0 0 320 512" height="1em" width="1em"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z"></path>
                                    </svg>
                                    Hướng dẫn thanh toán VNPAY</Link><br/>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-sm-3 mb-3">
                        <div className="contact-info">
                            <p className="mb-4 heding"><strong>CHÍNH SÁCH</strong></p>
                            <div className="list_menu">
                                <Link className="text-light" to="/s/chinh-sach-bao-hanh">
                                    <svg stroke="currentColor" fill="currentColor" stroke-width="0"
                                         viewBox="0 0 320 512" height="1em" width="1em"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z"></path>
                                    </svg>
                                    Chính sách bảo hành</Link><br/>
                                <Link className="text-light" to="/s/chinh-sach-doi-tra-va-hoan-tien">
                                    <svg stroke="currentColor" fill="currentColor" stroke-width="0"
                                         viewBox="0 0 320 512" height="1em" width="1em"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z"></path>
                                    </svg>
                                    Chính sách đổi trả và hoàn tiền</Link><br/>
                                <Link className="text-light" to="/s/quy-che-hoat-dong-website-ban-hang-gocare">
                                    <svg stroke="currentColor" fill="currentColor" stroke-width="0"
                                         viewBox="0 0 320 512" height="1em" width="1em"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z"></path>
                                    </svg>
                                    Quy chế hoạt động website</Link><br/>
                                <Link className="text-light" to="/s/chinh-sach-van-chuyen-va-giao-nhan">
                                    <svg stroke="currentColor" fill="currentColor" stroke-width="0"
                                         viewBox="0 0 320 512" height="1em" width="1em"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z"></path>
                                    </svg>
                                    Chính sách vận chuyển và giao nhận</Link><br/>
                                <Link className="text-light" to="/s/chinh-sach-thanh-toan">
                                    <svg stroke="currentColor" fill="currentColor" stroke-width="0"
                                         viewBox="0 0 320 512" height="1em" width="1em"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z"></path>
                                    </svg>
                                    Chính sách thanh toán</Link><br/>
                                <Link className="text-light" to="/s/chinh-sach-bao-mat-thong-tin">
                                    <svg stroke="currentColor" fill="currentColor" stroke-width="0"
                                         viewBox="0 0 320 512" height="1em" width="1em"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z"></path>
                                    </svg>
                                    Chính sách bảo mật thông tin</Link><br/>
                                    <Link className="text-light" to="/s/chinh-sach-kiem-hang">
                                    <svg stroke="currentColor" fill="currentColor" stroke-width="0"
                                         viewBox="0 0 320 512" height="1em" width="1em"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z"></path>
                                    </svg>
                                        Chính sách kiểm hàng</Link><br/>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-sm-2 mb-2">
                        <div className="contact-info">
                            {this.props.third}
                        </div>
                    </div>
                </div>
                <div className="row justify-content-md-center mt-1">
                    <div className="col-md-3 co col-xs-12  text-center">
                        <a href='http://online.gov.vn/Home/WebDetails/112384'>
                            <img style={{width: '125px'}} alt='' title='' src='http://admin.gocare.vn/files/4nzpx1sjuo0d2hcj54ce/logoSaleNoti.png'/>
                        </a>
                    </div>
                </div>
            </div>
        );
    }
}
