import Request from "./request";

export default class Api {
    // protected endpoint = "http://localhost:8000/api/v2/";
    protected endpoint = process.env.REACT_APP_API_ENDPOINT;
    protected controllers: any = {};
    
    async get(url: string, params = {}, headers = {}): Promise<any> {
        if (typeof this.controllers[url] !== 'undefined') {
            this.controllers[url].abort();
        }
        this.controllers[url] = new AbortController();
        const response = await fetch(this.url(url, params), {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                ...headers
            },
            signal: this.controllers[url].signal
        });
        if (response.status == 200) {
            return response.json();
        }
        throw response;
    }

    async post(url: string, params = {}, headers = {}): Promise<any> {
        const response = await fetch(this.endpoint + url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                ...headers
            },
            body: JSON.stringify(params)
        });
        if (response.status == 200) {
            return response.json();
        }
        throw response.json();
    }

    async put(url: string, params = {}, headers = {}): Promise<any> {
        const response = await fetch(this.endpoint + url, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                ...headers
            },
            body: JSON.stringify(params)
        });
        if (response.status == 200) {
            return response.json();
        }
        throw response.json();
    }

    async delete(url: string, params = {}, headers = {}): Promise<any> {
        const response = await fetch(this.endpoint + url, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                ...headers
            },
            body: JSON.stringify(params)
        });
        if (response.status == 200) {
            return response.json();
        }
        throw response.json();
    }

    protected url(url: any, params: any): string {

        return this.endpoint + url + '?' + (new Request(params).httpBuildQuery());
    }

    protected buildHttpQuery(params: any): string {
        return new Request(params).httpBuildQuery();
    }
}