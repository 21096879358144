import React, {lazy} from "react";
import PublicRoute from "./PublicRoute";
import PrivateRoute from "./PrivateRoute";

import { Redirect } from "react-router-dom";

import ServerRendingPage from "../pages/ServerRending";

const Contact = lazy(() => import("../pages/Contact"));
const Homepage = lazy(() => import("../pages/Homepage"));
const WarrantyPage = lazy(() => import("../pages/WarrantyPage"));
const ProductDetailPage = lazy(() => import("../pages/Product"));
const ProductsPage = lazy(() => import("../pages/Products"));
const DichvuPage = lazy(() => import("../pages/Dichvu"));
const NotFoundPage = lazy(() => import("../components/errors/not-found"));
const LoginPage = lazy(() => import("../pages/LoginPage"));
const CartPage = lazy(() => import("../pages/Cart"));
const CheckoutPage = lazy(() => import("../pages/Checkout"));
const AccountPage = lazy(() => import("../pages/AccountPage"));
const OrderList = lazy(() => import("../pages/OrderList"));
const SeriList = lazy(() => import("../pages/SeriList"));
const AuditPage = lazy(() => import("../pages/AuditPage"));
const Order = lazy(() => import("../pages/Order"));
const OrderCreated = lazy(() => import("../pages/OrderCreated"));
const AddressPage = lazy(() => import("../pages/AddressPage"));
const RequestWarranty = lazy(() => import("../pages/RequestWarranty"));
const RequestWarrantyList = lazy(() => import("../pages/WarrantyRequests"));
const ProductCategoryPage = lazy(() => import("../pages/ProductCategory"));
const Vouchers = lazy(() => import("../pages/VouchersPage"));
const SubAccountPage = lazy(() => import("../pages/SubAccountPage"));
const SeriPage = lazy(() => import("../pages/SeriPage"));
const TransferOrderPage = lazy(() => import("../pages/TransferOrderPage"));
const RevenuePage = lazy(() => import("../pages/RevenuePage"));
const LuckyDrawPage = lazy(() => import("../pages/LuckyDrawPage"));
const PostsPage = lazy(() => import("../pages/Posts"));
const PostPage = lazy(() => import("../pages/Post"));
const FaqsPage = lazy(() => import("../pages/Faqs"));
const ActivationPage = lazy(() => import("../pages/ActivationPage"));
const VideoPage = lazy(() => import("../pages/VideoPage"));

export const routeTitles: { [key: string]: string } = {
    "dich-vu-gocare": "Dịch vụ Gocare",
    "lich-su-bao-hanh": "Lịch sử bảo hành",
    "so-dia-chi": "Sổ địa chỉ",
    "tai-khoan": "Tài khoản",
    "quan-ly-don-hang": "Quản lý đơn hàng",
    "lich-su-thay-doi-so-du": "Lịch sử thay đổi số dư/công nợ",
    "don-hang": "Đơn hàng",
    "thanh-toan": "Thanh toán",
    "gio-hang": "Giỏ hàng",
    "dang-nhap": "Đăng nhập",
    "kick-hoat-bao-hanh": "Kích hoạt bảo hành",
    "yeu-cau-bao-hanh": "Yêu cầu bảo hành",
    bh: "Bảo hành",
    "san-pham": "Sản phẩm",
    "danh-muc": "Danh mục",
    "ma-khuyen-mai": "Mã khuyến mại",
    "tai-khoan-con": "Tài khoản con",
    "quan-ly-seri": "Quản lý seri",
    "quan-ly-xuat-nhap/1": "Đơn hàng tài khoản con",
    "quan-ly-nhap-nhap/2": "Đơn hàng từ tài khoản cấp trên",
    "thong-ke": "Thống kê",
    "chinh-sach-bao-hanh": "Chính sách bảo hành",
    "chinh-sach-doi-tra-va-hoan-tien": "Chính sách Đổi trả hàng và hoàn tiền",
    "quy-che-hoat-dong-website-ban-hang-gocare": "Quy chế hoạt động website bán hàng trực tuyến gocare.vn",
    "chinh-sach-van-chuyen-va-giao-nhan": "Chính sách vận chuyển và giao nhận",
    "chinh-sach-thanh-toan": "Chính sách thanh toán",
    "chinh-sach-bao-mat-thong-tin": "Chính sách bảo mật thông tin",
    "ho-tro": "Hỗ trợ",
    "nguoi-lon-tuoi": "Dịch vụ cho người lớn tuổi",
    "nguoi-tre-tuoi": "Dịch vụ cho người trẻ tuổi",
    "tre-nho-hoc-sinh": "Dịch vụ cho trẻ nhỏ học sinh",
    'dat-hang-thanh-cong': "Đặt hàng thành công",
    'thoa-thuan': "Thoả thuận",
    'quyen-rieng-tu': "Quyền riêng tư",
    "quan-ly-ma-kich-hoat": "Quản lý mã kích hoạt",
    'kick-hoat': "Kích hoạt mã seri",
    'chinh-sach-kiem-hang': "Chính sách kiểm hàng",
};

export const routes = [
    {
        path: "/",
        component: Homepage,
        exact: true,
        type: PublicRoute,
    }, {
        path: "/lien-he",
        component: Contact,
        exact: true,
        type: PublicRoute,
    },
    {
        path: "/lich-su-bao-hanh",
        component: RequestWarrantyList,
        exact: true,
        type: PublicRoute,
    },
    {
        path: "/so-dia-chi",
        component: AddressPage,
        exact: true,
        type: PrivateRoute,
    },
    {
        path: "/tai-khoan",
        component: AccountPage,
        exact: true,
        type: PrivateRoute,
    },
    {
        path: "/quan-ly-don-hang",
        component: OrderList,
        exact: true,
        type: PrivateRoute,
    },
    {
        path: "/lich-su-thay-doi-so-du",
        component: AuditPage,
        exact: true,
        type: PrivateRoute,
    },
    {
        path: "/don-hang/:type/:id",
        component: Order,
        exact: true,
        type: PrivateRoute,
    },
    {
        path: "/thanh-toan",
        component: CheckoutPage,
        exact: true,
        type: PublicRoute,
    },
    {
        path: "/gio-hang",
        component: CartPage,
        exact: true,
        type: PublicRoute,
    },
    {
        path: "/dang-nhap",
        component: LoginPage,
        exact: true,
        type: PublicRoute,
    },
    {
        path: "/kick-hoat-bao-hanh/:id/:seri",
        component: WarrantyPage,
        exact: true,
        type: PublicRoute,
    },
    {
        path: "/kick-hoat-bao-hanh",
        component: WarrantyPage,
        exact: true,
        type: PublicRoute,
    },
    {
        path: "/yeu-cau-bao-hanh/:seri",
        component: RequestWarranty,
        exact: true,
        type: PublicRoute,
    },
    {
        path: "/bh/:seri",
        component: WarrantyPage,
        exact: true,
        type: PublicRoute,
    },
    {
        path: "/san-pham/:sku",
        component: ProductDetailPage,
        exact: true,
        type: PublicRoute,
    },
    {
        path: "/thiet-bi",
        component: ProductsPage,
        exact: true,
        type: PublicRoute,
    },
    {
        path: "/dich-vu",
        component: DichvuPage,
        exact: true,
        type: PublicRoute,
    },
    {
        path: "/videos",
        component: VideoPage,
        exact: true,
        type: PublicRoute
    },
    {
        path: "/danh-muc/:slug",
        component: ProductCategoryPage,
        exact: true,
        type: PublicRoute,
    },
    {
        path: "/s/:slug",
        component: ServerRendingPage,
        exact: true,
        type: PublicRoute,
    },
    {
        path: "/chinh-sach",
        component: () => <Redirect to="/s/quyen-rieng-tu" />,
        exact: true,
        type: PublicRoute,
    },
    {
        path: "/ma-khuyen-mai",
        component: Vouchers,
        exact: true,
        type: PrivateRoute,
    },
    {
        path: "/tai-khoan-con",
        component: SubAccountPage,
        exact: true,
        type: PrivateRoute,
    },
    {
        path: "/quan-ly-seri",
        component: SeriPage,
        exact: true,
        type: PrivateRoute,
    },
    {
        path: "/quan-ly-xuat-nhap/:type",
        component: TransferOrderPage,
        exact: true,
        type: PrivateRoute,
    },
    {
        path: "/thong-ke",
        component: RevenuePage,
        exact: true,
        type: PrivateRoute,
    },
    {
        path: "/lucky-draw",
        component: LuckyDrawPage,
        exact: true,
        type: PrivateRoute,
    },
    {
        path: "/dat-hang-thanh-cong/:accesskey",
        component: OrderCreated,
        exact: true,
        type: PublicRoute,
    },
    {
        path: "/tin-tuc",
        component: PostsPage,
        exact: true,
        type: PublicRoute,
    }, {
        path: "/faqs",
        component: FaqsPage,
        exact: true,
        type: PublicRoute,
    },
    {
        path: "/p/:id",
        component: PostPage,
        exact: true,
        type: PublicRoute,
    },
    {
        path: "/quan-ly-ma-kich-hoat",
        component: SeriList,
        exact: true,
        type: PrivateRoute,
    },
    {
        path: "/kich-hoat",
        component: ActivationPage,
        exact: true,
        type: PrivateRoute,
    },
    {
        path: "*",
        component: NotFoundPage,
        exact: true,
        type: PublicRoute,
    },
];

