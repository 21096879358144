import { Deserializable } from "./deserializable.interface";
import Model from "./model";
import Order from "./order";
import Product from "./product";
import Address from "./address";
import Audit from "./audit";
import User from "./user";
import RequestWarrantyModel from "./requestWarranty";
import TransferOrder from "./transfer-order";
import Page from "./page";
import Post from "./post";
import Serial from "./serial";

export default class Pagination extends Model implements Deserializable<Pagination> {
    page: number = 1;
    perpage: number = 12;
    hasMore: boolean = false;
    items: Model[] = [];

    fromJson(input: {}): Pagination {
        super.fromJson(input);

        return this;
    }

    append(input: any): Pagination {
        this.hasMore = input.hasMore;

        return this;
    }

    getItems(): Model[] {
        return this.items;
    }
}

export class ProductPagination extends Pagination implements Deserializable<Pagination>
{
    items: Product[] = [];

    fromJson(input: any): ProductPagination {
        super.fromJson(input);
        this.items = [];
        if (typeof input.items !== 'undefined') {
            this.items = input.items.map((item: {}) => {
                return new Product().fromJson(item);
            });
        }

        return this;
    }

    append(input: any): ProductPagination {
        this.hasMore = input.hasMore;
        if (typeof input.items !== 'undefined') {
            input.items.map((item: {}) => {
                return this.items.push(new Product().fromJson(item));
            });
        }

        return this;
    }

    getItems(): Product[] {
        return this.items;
    }
}

export class OrderPagination extends Pagination implements Deserializable<Pagination>
{
    items: Order[] = [];

    fromJson(input: any): OrderPagination {
        super.fromJson(input);
        this.items = [];
        if (typeof input.items !== 'undefined') {
            this.items = input.items.map((item: {}) => {
                return new Order().fromJson(item);
            });
        }
        return this;
    }

    getItems(): Order[] {
        return this.items;
    }
}

export class AddressPagination extends Pagination implements Deserializable<Pagination>
{
    items: Address[] = [];

    fromJson(input: any): AddressPagination {
        super.fromJson(input);
        this.items = [];
        if (typeof input.items !== 'undefined') {
            this.items = input.items.map((item: {}) => {
                return new Address().fromJson(item);
            });
        }
        return this;
    }

    append(input: any): AddressPagination {
        this.hasMore = input.hasMore;
        if (typeof input.items !== 'undefined') {
            input.items.map((item: {}) => {
                return this.items.push(new Address().fromJson(item));
            });
        }

        return this;
    }

    getItems(): Address[] {
        return this.items;
    }
}

export class RequestWarrantyPagination extends Pagination implements Deserializable<Pagination>
{
    items: RequestWarrantyModel[] = [];

    fromJson(input: any): RequestWarrantyPagination {
        super.fromJson(input);
        this.items = [];
        if (typeof input.items !== 'undefined') {
            this.items = input.items.map((item: {}) => {
                return new RequestWarrantyModel().fromJson(item);
            });
        }
        return this;
    }

    getItems(): RequestWarrantyModel[] {
        return this.items;
    }
}

export class AuditPagination extends Pagination implements Deserializable<Pagination>
{
    items: Audit[] = [];

    fromJson(input: any): AuditPagination {
        super.fromJson(input);
        this.items = [];
        if (typeof input.items !== 'undefined') {
            this.items = input.items.map((item: {}) => {
                return new Audit().fromJson(item);
            });
        }
        return this;
    }

    getItems(): Audit[] {
        return this.items;
    }
}

export class CustomerPagination extends Pagination implements Deserializable<Pagination>
{
    items: User[] = [];

    fromJson(input: any): CustomerPagination {
        super.fromJson(input);
        this.items = [];
        if (typeof input.items !== 'undefined') {
            this.items = input.items.map((item: {}) => {
                return new User().fromJson(item);
            });
        }
        return this;
    }

    getItems(): User[] {
        return this.items;
    }
}

export class TransferOrderPagination extends Pagination implements Deserializable<Pagination>
{
    items: TransferOrder[] = [];

    fromJson(input: any): TransferOrderPagination {
        super.fromJson(input);
        this.items = [];
        if (typeof input.items !== 'undefined') {
            this.items = input.items.map((item: {}) => {
                return new TransferOrder().fromJson(item);
            });
        }
        return this;
    }

    getItems(): TransferOrder[] {
        return this.items;
    }
}

export class ProductSeriPagination extends Pagination implements Deserializable<Pagination>
{
    items: any[] = [];

    fromJson(input: any): ProductSeriPagination {
        super.fromJson(input);
        return this;
    }

    getItems(): any[] {
        return this.items;
    }
}

export class PostPagination extends Pagination implements Deserializable<Pagination>
{
    items: Post[] = [];

    fromJson(input: any): PostPagination {
        super.fromJson(input);
        this.items = [];
        if (typeof input.items !== 'undefined') {
            this.items = input.items.map((item: {}) => {
                return new Post().fromJson(item);
            });
        }
        return this;
    }

    append(input: any): PostPagination {
        this.hasMore = input.hasMore;
        if (typeof input.items !== 'undefined') {
            input.items.map((item: {}) => {
                return this.items.push(new Post().fromJson(item));
            });
        }

        return this;
    }

    getItems(): Post[] {
        return this.items;
    }
}

export class SerialPagination extends Pagination implements Deserializable<Pagination>
{
    items: Serial[] = [];

    fromJson(input: any): SerialPagination {
        super.fromJson(input);
        this.items = [];
        if (typeof input.items !== 'undefined') {
            this.items = input.items.map((item: {}) => {
                return new Serial().fromJson(item);
            });
        }
        return this;
    }

    getItems(): Serial[] {
        return this.items;
    }
}
