import React from "react";
import { Link } from "react-router-dom";
import { useAuthContext } from "../../contexts/AuthContext";
import { logout } from "../../actions/AuthAction";
import { Dropdown } from "react-bootstrap";
import "./user.scss";

interface Props {
    color?: string;
    transform?: string;
    isMobile?: boolean;
}

const defaultProps: Props = {
    color: "white",
    transform: "",
    isMobile: false,
};

const User: React.FC<Props> = (props) => {
    const { authState, dispatch } = useAuthContext();
    const { loggedIn, user } = authState;

    const handleLogout = async () => {
        await logout(dispatch);
    };

    const CustomToggle = React.forwardRef(
        (
            props: {
                color: string;
                transform?: string;
                children?: React.ReactNode;
                onClick: (
                    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
                ) => {};
            },
            ref: React.Ref<HTMLAnchorElement>
        ) => (
            <a
                className={`nav-link text-${props.color} text-${props.transform}`}
                href=""
                ref={ref}
                onClick={(e) => {
                    e.preventDefault();
                    props.onClick(e);
                }}
            >
                {props.children}
                <span style={{ paddingLeft: "5px" }}>&#x25bc;</span>
            </a>
        )
    );

    return (
        <>
            {!loggedIn ? (
                <Link
                    to="/dang-nhap"
                    className={`nav-link text-${props.color} text-${props.transform}`}
                >
                    <i className="bi bi-person-circle"></i>
                </Link>
            ) : (
                <Dropdown
                    className={`user-dropdown ${
                        props.isMobile ? "nav-link px-0" : ""
                    }`}
                    onClick={(e: any) => e.stopPropagation()} 
                >
                    <Dropdown.Toggle
                        as={CustomToggle}
                        id="dropdown-custom-components"
                        color={props.color}
                        transform={props.transform}
                    >
                        {user.name}
                    </Dropdown.Toggle>
                    <Dropdown.Menu align="right" className="user_menu_sub">
                        {/*<Dropdown.ItemText className="user-balance">*/}
                        {/*    {user.isCtvWithDebt() ? 'Công nợ' : 'Số dư'}:*/}
                        {/*    <strong className="text-danger pl-1">*/}
                        {/*        {`${user.balance.toLocaleString()} đ`}*/}
                        {/*    </strong>*/}
                        {/*    {!user.isCtvWithDebt() */}
                        {/*    ? */}
                        {/*        <>*/}
                        {/*            <br />*/}
                        {/*            Khoá:*/}
                        {/*            <strong className="text-danger pl-1">*/}
                        {/*                {`${user.locked_balance.toLocaleString()} đ`}*/}
                        {/*            </strong>*/}
                        {/*        </>*/}
                        {/*    : ''}*/}
                        {/*    /!*<br />*!/*/}
                        {/*    /!*Điểm AZ Point: <strong className="text-danger pl-1">{`${user.az_point.toLocaleString()}`}</strong>*!/*/}
                        {/*</Dropdown.ItemText>*/}
                        {/*<Dropdown.Divider />*/}
                        <Dropdown.Item href="/tai-khoan">
                            Tài khoản
                        </Dropdown.Item>
                        <Dropdown.Item href="/quan-ly-don-hang">
                            Đơn hàng
                        </Dropdown.Item>
                        <Dropdown.Item href="/so-dia-chi">
                            Sổ địa chỉ
                        </Dropdown.Item>
                        <Dropdown.Divider />
                        <Dropdown.Item onClick={handleLogout}>
                            Đăng xuất
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            )}
        </>
    );
};

User.defaultProps = defaultProps;

export default User;

