import Combo from "../models/combo";
import { CartItem } from "../reducers/CartReducer";

export const getLocalData = () => {
    const localData = localStorage.getItem("cart");
    return localData ? JSON.parse(localData) : [];
}

export const getTotalItemsInCart = (items: CartItem[]) => {
    return items.reduce((total: any, item: CartItem) => item.quantity + total, 0);
};

export const getCartItemTotal = (item: CartItem) => {
    return item.price * item.quantity;
}

export const getCartSubTotal = (items: CartItem[]) => {
    return items.reduce((total: any, item: CartItem) => {
        const comboTotal = typeof item.combos != 'undefined' ? item.combos.reduce((total: any, combo: Combo) => {
            return combo.related[0].retail_price * combo.quantity;
        }, 0) : [];
        return (getCartItemTotal(item) + comboTotal) + total
    }, 0);
}

export const getCalculateCartTotal = (items: CartItem[]) => {
    const totalComboDiscount = getCartComboDiscountTotal(items);
    const subTotal = getCartSubTotal(items);
    return (subTotal - totalComboDiscount).toLocaleString() + 'đ';
}

export const calculateCheckoutTotal = (items: CartItem[], shippingFee: number = 0, discount: number = 0) => {
    const subTotal = getCartSubTotal(items);
    return (subTotal + shippingFee - discount).toLocaleString() + 'đ';
}

export const getCartComboDiscountTotal = (items: CartItem[]) => {
    let total = 0;
    for (let i = 0; i < items.length; ++i) {
        let item = items[i];
        if (typeof item.combos != 'undefined') {
            for (let j = 0; j < item.combos.length; ++j) {
                let combo = item.combos[j];
                total += combo.discount;
            }
        }
    }
    return total;
}