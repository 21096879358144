import React from 'react';
import Loading from "./../components/commons/loading";
import PageService from '../services/page';
import Page from '../models/page';
import { Redirect } from "react-router-dom";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import HelmetData from "../components/metas/helmet";

export default class ServerRendingPage extends React.Component<any, { isLoading: boolean, page: Page, is404: boolean }> {
  protected pageService: PageService = new PageService;
  protected isNotFound: boolean = false;

  constructor(props: any) {
    super(props);
    this.state = {
      isLoading: false,
      page: new Page().fromJson({}),
      is404: false
    };
  }

  componentDidMount() {
    if (typeof this.props.match.params.slug != 'undefined' && this.props.match.params.slug) {
      this.loadPageBySlug(this.props.match.params.slug);
    }
  }
  componentWillReceiveProps(newProps: any) {
    if (typeof newProps.match.params.slug != 'undefined' && newProps.match.params.slug) {
      this.loadPageBySlug(newProps.match.params.slug);
    }

    return true;
  }

  loadPageBySlug(slug: string) {
    if (slug !== null) {
      this.setState({
        isLoading: true
      });
      this.pageService.get(slug)
        .then((response: any) => {
          this.setState({
            isLoading: false,
            is404: false,
            page: new Page().fromJson(response.data)
          })
        })
    }
  }

  render() {
    return (

      <div className="container">
        <HelmetData
            title={this.state.page.title}
            quote={this.state.page.title}
        />
        <Loading isLoading={this.state.isLoading}>

          <div className="row mt-3 box-content">
            <div className="col-12">
              <div dangerouslySetInnerHTML={{
                __html: this.state.page.content
              }}>
              </div>
              {this.state.is404 ? <Redirect from="*" to="/404" /> : ''}
            </div>
          </div>
        </Loading>
      </div>
    );
  }
}
