import React, { useEffect, useCallback } from "react";
import logo from "./../../assets/images/img.png";
import "./App.scss";
import Header from "./../../components/headers/v1/header";
import Footer from "./../../components/footers/three-columns";
import Info from "./../../components/contacts/info";
import Fanpage from "./../../components/contacts/fanpage";
import CustomerSupport from "./../../components/contacts/customer-support";
import Copyright from "./../../components/footers/copyright";
import { routes } from "../../routes";
import HelmetData from "../../components/metas/helmet";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import vi from "date-fns/locale/vi";

import { currentUser } from "../../actions/AuthAction";
import { useAuthContext } from "../../contexts/AuthContext";
import ScrollToTop from "./ScrollToTop";
export default function App() {
    registerLocale("vi", vi);
    const { dispatch } = useAuthContext();

    const header = {
        hotline: "CSKH : " + process.env.REACT_APP_HOTLINE,
        logo: logo,
    };

    const checkCurrentCustomer = useCallback(async () => {
        await currentUser(dispatch);
    }, [dispatch]);

    useEffect(() => {
        checkCurrentCustomer();
    }, [checkCurrentCustomer]);

    useEffect(() => {
        document.title = process.env.REACT_APP_NAME + "";
    }, []);

    const renderRoutes = useCallback((routes) => {
        let results = null;
        if (routes.length > 0) {
            results = routes.map((route: any, index: any) => {
                const { path, exact, component, type: RouteType } = route;
                return (
                    <RouteType
                        key={index}
                        path={path}
                        exact={exact}
                        component={component}
                    />
                );
            });
        }
        return <Switch>{results}</Switch>;
    }, []);

    return (
        <Router>
            <ScrollToTop />
            <HelmetData />
            <Header {...header} />
                {renderRoutes(routes)}
            <div className="footer" style={{backgroundImage: 'url("assets/img/bg/14.png")'}}>
                <Footer
                    first={<Info />}
                    third={<Fanpage />}
                    second={<CustomerSupport />}
                />
                <Copyright />
            </div>
        </Router>
    );
}

