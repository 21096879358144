import React from 'react';
import fb from "./../../assets/icons/fb.svg";
import yt from "./../../assets/icons/yt.svg";

export default class Fanpage extends React.Component {
    render() {
        return (
            <div className="contact-info ft-social">
                <p className="mb-4"><strong>KẾT NỐI CHÚNG TÔI</strong></p>
                <p>
                    <a href="https://www.facebook.com/gocare.vn" className="icon" target="_blank" title="Facebook">
                        <img src={fb} width="32" alt=""/>
                    </a>
                    <a href="https://gocare.vn/" className="icon" target="_blank" title="Youtube">
                        <img src={yt} width="32" alt=""/>
                    </a>
                </p>
                <div className="app_icon_footer">
                    <div><a className="d-lg-block mb-3"
                                                                  href="https://apps.apple.com/vn/app/gocare/id6451886592"
                                                                  target="_blank">


                        <img
                            src="/assets/img/home/anyconv-com-app-store-icon.png"
                            title="" alt="" className="mr-1"/>
                    </a> <a className="qr-code d-lg-block mb-3"
                            href="https://apps.apple.com/vn/app/gocare/id6451886592" target="_blank">
                        <img src="/assets/img/home/applestore.png" title="" alt="" width="80px"/>

                    </a></div>

                    <div><a className="d-lg-block mb-3"
                                                                  href="https://play.google.com/store/apps/details?id=vn.com.tma.gocare"
                                                                  target="_blank">


                        <img
                            src="/assets/img/home/anyconv-com-gg-play.png"
                            title="" alt="" className="mr-1"/>
                    </a> <a className="qr-code d-lg-block mb-3"
                            href="https://play.google.com/store/apps/details?id=vn.com.tma.gocare" target="_blank">
                        <img src="/assets/img/home/googplay.png" title="" alt="" width="80px"/>

                    </a></div>
                </div>
            </div>
        );
    }
}