import { Deserializable } from "./deserializable.interface";
import Model from "./model";
import Product from "./product";

export default class Combo extends Model implements Deserializable<Combo> {
  id: number = 0;
  note: string = "";
  product_id: number = 0;
  quantity: number = 0;
  total: any = 0;
  status: number = 1;
  discount: number = 0;
  related: Product[] = [];

  fromJson(input: any): Combo {
    super.fromJson(input);
    if (typeof input.related !== "undefined") {
      this.related = input.related.map((item: any) => {
        return new Product().fromJson(item);
      });
    }

    this.total = this.related.reduce((total: any, product: Product) => {
      return (this.quantity * product.retail_price) + total;
    }, 0);

    return this;
  }
}
