import { Deserializable } from "./deserializable.interface";
import Model from "./model";
import Product from "./product";

export default class User extends Model implements Deserializable<User> {
    id!: number;
    code!: string;
    name!: string;
    phone!: string;
    debt!: number;
    total_order!: number;
    total_sold_amount!: number;
    profit!: number;
    address!: string;
    province!: string;
    district!: string;
    ward!: string;
    group!: string;
    can_discount!: boolean;
    can_apply_voucher: boolean = true;
    balance: number = 0;
    locked_balance: number = 0;
    hasOwnedShippingService: boolean = false;
    available_shipping_setups: ShippingSetup[] = [];
    ableToPlaceAnOrder: boolean = true;
    can_create_sub: number = 0;
    created_at: string = '';
    az_point: number = 0;
    number_of_orders: number = 0;
    total_amount: number = 0;
    has_parent: boolean = false;
    require_payment: boolean = true;
    cccd: string = '';
    bank_name: string = '';
    bank_acc_name: string = '';
    bank_acc: string = '';
    discount_by_cate: any = {};


    fromJson(input: any) {
        super.fromJson(input);
        if (typeof input.available_shipping_setups !== "undefined") {
            this.available_shipping_setups =
                input.available_shipping_setups.map((setup: ShippingSetup) => {
                    return new ShippingSetup().fromJson(setup);
                });
        }

        this.ableToPlaceAnOrder = !this.isKhachLe() || this.phone != '';
        if (typeof this.code == 'undefined' && typeof this.id != 'undefined') {
            this.code = 'DL-' + this.id;
        }
        
        return this;
    }

    isCTV() {
        return this.group === "ctv_dien_tu";
    }

    isAgent()
    {
        return this.group == 'dai_ly'
    }

    isKhachLe()
    {
        return this.group === "khach_le";
    }

    getDebt() {
        return typeof this.debt !== "undefined"
            ? this.debt.toLocaleString() + " đ"
            : "0 đ";
    }

    getTotalSoldAmount() {
        return typeof this.total_sold_amount !== "undefined"
            ? this.total_sold_amount.toLocaleString() + " đ"
            : "0 đ";
    }

    getProfit() {
        return typeof this.profit !== "undefined"
            ? this.profit.toLocaleString() + " đ"
            : "0 đ";
    }

    isCtvWithDebt()
    {
        return this.hasOwnedShippingService;
    }

    getExtraInfo()
    {
        return [
            this.cccd,
            this.bank_name,
            this.bank_acc_name,
            this.bank_acc,
        ]
          .filter(v => v && v.trim())
          .join('-')
    }

    getDiscountByCate(products: Product[])
    {
        let productByCate: any = {};
        products.map((product: Product) => {
            if (typeof productByCate[product.cate_id] === 'undefined') {
                productByCate[product.cate_id] = {
                    cate_id: product.cate_id,
                    cate_name: product.cate_name,
                    quantity: 0,
                    amount: 0,
                    discount: 0,
                    discount_1: 0,
                    total: 0,
                }
            }
            productByCate[product.cate_id].quantity += product.quantity;
            productByCate[product.cate_id].amount += product.quantity * product.price;
        });

        productByCate = Object.values(productByCate).map((value: any) => {
            if (typeof this.discount_by_cate[value.cate_id] !== 'undefined') {
                const discount = this.discount_by_cate[value.cate_id];
                Object.values(discount).map((dp: any) => {
                    if (value.quantity >= dp.quantity) {
                        value.discount = dp.discount;
                        value.discount_1 = dp.discount_1;
                        value.total = value.amount * value.discount_1 / 100 + value.discount;
                    }
                })
            }

            return value;
        })

        return productByCate;
    }

    getTotalDiscountByCate(products: Product[])
    {
        return this.getDiscountByCate(products).reduce((a: any,curr: any) => a + curr.total, 0);
    }
}

export class ShippingSetup
    extends Model
    implements Deserializable<ShippingSetup>
{
    name!: string;
    key!: string;

    fromJson(input: any): ShippingSetup {
        super.fromJson(input);

        return this;
    }
}
