import React from 'react';

export default class Copyright extends React.Component <{}, {}>{
    render() {
        return (
            <div className="row border-footer container m-auto px-0 pt-2">
                <div className="col-12 col-sm-6 copyright_left p-0">
                    ©2023 - Bản quyền của Gocare.vn
                </div>
                <div className="col-12 col-sm-6 copyright_right p-0">Giấy chứng nhận đăng ký kinh doanh số: 0108294845; Ngày cấp: 28/05/2018</div>
            </div>
        );
    }
}