import React from 'react';
import { Link } from "react-router-dom";
import User from "../user/user";
import AutoCompleteSearch from '../searches/auto-complete-search';
import "./main-menu.scss";
import Cart from '../cart/cart';
import {Dropdown} from "react-bootstrap";
import Category from "../../models/category";
export default class MobileMainMenu extends React.Component<any, any>{
    constructor(props: any) {
        super(props);
        this.state = {
            dropdown: 0,
        };
        this.toggleDropdown = this.toggleDropdown.bind(this);

    }

    toggleDropdown(event: any) {
        this.setState((prevState: any) => ({
            dropdown: !prevState.dropdown
        }));
    }

    render() {
        const search = <AutoCompleteSearch showButton="true" />;
        const menu = <ul className="nav flex-column text-dark  main-menu">
            <li className="nav-item row m-0">
                <div className="col-6 p-2 text-center">
                    <img width="70%" src={this.props.logo} alt="Logo" />
                </div>
                <div className="col-6 py-2 text-right pr-1" style={{fontSize: '16px'}}>
                    <User color="dark" transform="uppercase" isMobile={true} />
                </div>

            </li>
            <li className="nav-item">
                <div onClick={e => e.stopPropagation()} className="col-12 p-2 search-box-activated">{search}</div>
            </li>
            <li className="nav-item border-bottom">
                <Link to="/" className="nav-link text-dark">TRANG CHỦ</Link>
            </li>
            <li className="nav-item border-bottom">
                <div className="topnav responsive">
                    <div className="dropdown">
                        <a className="dropbtn text-dark dropdown-toggle" onClick={(e: any) => e.stopPropagation()}>DỊCH VỤ
                            <i className="fa fa-caret-down"></i>
                        </a>
                        <div className="dropdown-content">
                            {
                                this.props.categories.length > 0 && this.props.categories.map((item: Category) => {
                                    if (!item.is_devices) {
                                        return <Link
                                            to={{
                                                pathname: `/danh-muc/${item.slug}`,
                                                state: {name: item.name, slug: item.slug}
                                            }}
                                            className="dropdown-item"
                                        >
                                            {item.name}
                                        </Link>;
                                    }
                                })
                            }
                        </div>
                    </div>
                </div>
            </li>
            <li className="nav-item border-bottom" >
                <div className="topnav responsive">
                    <div className="dropdown">
                        <span className="dropbtn text-dark dropdown-toggle" onClick={(e: any) => e.stopPropagation()}>THIẾT BỊ
                            <i className="fa fa-caret-down"></i>
                        </span>
                        <div className="dropdown-content">
                            {
                                this.props.categories.length > 0 && this.props.categories.map((item: Category) => {
                                    if (item.is_devices) {
                                        return <Link
                                            to={{
                                                pathname: `/danh-muc/${item.slug}`,
                                                state: {name: item.name, slug: item.slug}
                                            }}
                                            className="dropdown-item"
                                        >
                                            {item.name}
                                        </Link>;
                                    }
                                })
                            }
                        </div>
                    </div>
                </div>
            </li>
            <li className="nav-item border-bottom">
                <Link to="/tin-tuc/" className="nav-link text-dark">TIN TỨC</Link>
            </li>
            <li className="nav-item border-bottom">
                <Link to="/faqs/" className="nav-link text-dark">HỖ TRỢ</Link>
            </li>
            <li className="nav-item">
                <Link to="/lien-he" className="nav-link text-dark">LIÊN HỆ</Link>
            </li>

        </ul>;


        return (
            <div className="row">
                <div className="col-3 pt-2 pl-4">
                    <button className="btn btn-light" onClick={this.toggleDropdown}><i className="bi bi-list"></i></button>
                </div>
                <div className="logo col-6 text-center">
                    <a href="/"><img src={this.props.logo} alt="Logo" /></a>
                </div>
                <div className="col-3 text-right pt-2 pr-5">
                    <Cart />
                </div>
                <div onClick={this.toggleDropdown} className={'col-12 mobile-main-menu-wrapper' + (this.state.dropdown ? ' active' : '') }>{menu}<div className="background"></div></div>
            </div>
        );
    }
}