import { Deserializable } from "./deserializable.interface";
import Model from "./model";
import Product from "./product";
import Order from "./order";

export default class Serial extends Model implements Deserializable<Serial> {
    product!: Product;
    serial_number!: string;
    status!: any;
    activated_at!: string;
    expired_at!: string;
    customer_name!: string;
    customer_phone!: string;
    customer_province!: string;
    activate_to_earn: boolean = false;

    activation_code!: string;

    purchased_date!: string;

    activated_date!: string;
    name!:string;
    phone!:string;
    email!:string;

    order!: Order;

    fromJson(input: any) : Serial {
        super.fromJson(input);

        if (typeof input.order !== 'undefined') {
            this.order = new Order().fromJson(input.order)
        }

        if (typeof input.product !== 'undefined') {
            this.product = new Product().fromJson(input.product)
        }


        return this;
    }

    getStatusLabel()
    {
        const label = [
          'Chưa thanh toán',
          'Đã thanh toán',
          'Đã kích hoạt',
          'Bị khoá'
        ];

        return this.status in label ? label[this.status] : '';
    }
}
