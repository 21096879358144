import React from 'react';
import { Link } from "react-router-dom";

export default class CustomerSupport extends React.Component {
    render() {
        const hotline = process.env.REACT_APP_HOTLINE;
        return (
            <div className="contact-info">
                <p className="mb-4"><strong>HỖ TRỢ KHÁCH HÀNG</strong></p>
                <p className="text-danger"><strong>CSKH và đặt hàng online: </strong></p>
                <p className="text-danger"><strong>{hotline}</strong></p>
            </div>
        );
    }
}